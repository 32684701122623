.product-foundation-vto {
  &__mobile-ctrl {
    .container {
      .product-sub-heading,
      .product-name {
        font-size: 12px;
      }
    }
  }
}
#colorbox {
  &.vto-ios-browser-error {
    background-color: $color-white;
  }
}

.vto-active {
  .elc-vto-foundation {
    .elc-vto-custom-shade {
      height: 65px;
    }
    .elc-vto-perfect-shade-label {
      white-space: nowrap;
    }
    .elc-vto-shade-info-left-container,
    .elc-vto-shade-info-right-container {
      width: 50%;
    }
    .elc-add-to-bag-button {
      float: $rdirection;
      padding: 15px;
      width: auto;
    }
    .elc-vto-custom-shade-picker-item-name {
      width: 32px;
    }
  }
}

.vto-skin-care-lux ~ .elc-vto-skin-care {
  .sd-product-grid {
    .elc-vto-skincare-product-grid {
      .elc-product-brief {
        &.step-added {
          p.elc-product-benefit-type {
            font-size: 20px;
          }
        }
        .elc-main-content-section {
          .elc-product-sub-display-name {
            font-size: 16px;
            line-height: 1;
            margin-bottom: 5px;
          }
          .elc-size-picker-container {
            margin-#{$rdirection}: 6px;
          }
          .elc-product-price-row-wrapper {
            margin-#{$rdirection}: 5px;
          }
          .elc-clickable-wrapper:has(.elc-write-first-review) {
            display: none;
          }
          .elc-clickable-wrapper:has(.elc-rating-component) {
            margin-bottom: 14px;
          }
        }
      }
    }
  }
}
