.cs-quick-info__block--live-chat {
  .cs-quick-info__title.el_jp_le_chat_live {
    background-position: 0 -1792px;
  }
}

.lp_radio_button label::before,
.lp_radio_button label::after {
  display: none;
}

.touch {
  #footer_chat {
    a {
      padding-left: 0px;
    }
  }
}

.lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_1 .lp_lpview_content:after,
.lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_2 .lp_lpview_content:after,
.lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_3 .lp_lpview_content:after,
.lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_4 .lp_lpview_content:after,
.lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_5 .lp_lpview_content:after,
.lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_6 .lp_lpview_content:after,
.lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_1 .lp_lpview_content:after,
.lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_2 .lp_lpview_content:after,
.lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_3 .lp_lpview_content:after,
.lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_4 .lp_lpview_content:after,
.lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_5 .lp_lpview_content:after,
.lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_6 .lp_lpview_content:after {
  position: relative;
  bottom: 21px;
  font-family: 'icons';
  font-size: 24px;
  float: right;
  display: inline-block;
  content: '';
}

.csstransforms .lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_1 .lp_lpview_content:after,
.csstransforms .lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_2 .lp_lpview_content:after,
.csstransforms .lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_3 .lp_lpview_content:after,
.csstransforms .lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_4 .lp_lpview_content:after,
.csstransforms .lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_5 .lp_lpview_content:after,
.csstransforms .lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_6 .lp_lpview_content:after,
.csstransforms .lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_1 .lp_lpview_content:after,
.csstransforms .lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_2 .lp_lpview_content:after,
.csstransforms .lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_3 .lp_lpview_content:after,
.csstransforms .lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_4 .lp_lpview_content:after,
.csstransforms .lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_5 .lp_lpview_content:after,
.csstransforms .lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_6 .lp_lpview_content:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-top: -4.5px;
  border-style: solid;
  border-color: transparent;
  border-left-width: 3px;
  border-right-width: 3px;
  border-top-width: 0;
  border-bottom-width: 6px;
  border-bottom-color: #040a2b;
  margin-top: 3px;
  margin-right: 6px;
}

#lpChat .lp_survey_area .lp_buttons_area,
#lpChat .lp_main_area .lp_buttons_area {
  button {
    text-transform: uppercase;
    font-weight: bold;
  }
}

#lpChat .lp_survey_area .lp_pages_area {
  .lp_radio_button label,
  .lp_checkbox_button label {
    font-size: 0.8em !important;
    margin-top: 0px !important;
  }
}

.lp_main {
  .lp_radiobutton_wrapper {
    input[type='radio'] ~ label {
      &::after,
      &::before {
        top: auto;
        left: 16px;
      }
    }
  }
}

@mixin txttrans() {
  &::-webkit-input-placeholder {
    text-transform: none;
  }
  &:-moz-placeholder {
    text-transform: none;
  }
  &:-ms-input-placeholder {
    text-transform: none;
  }
  &::-moz-placeholder {
    text-transform: none;
  }
}

#lpChat {
  input[type='text'] {
    @include txttrans();
  }
  input[type='email'] {
    @include txttrans();
  }
  textarea {
    @include txttrans();
  }
  select {
    text-transform: none;
  }
  option {
    text-transform: none;
  }
  .lp_buttons_area button {
    letter-spacing: 1px;
  }
}

.lp_desktop {
  #lpChat {
    .lp_survey_area {
      .lp_pages_area {
        .lp_question_wrapper .lp_question,
        .lp_lpview_content.lp_content {
          margin-top: 5px;
        }
        .lp_input-field,
        .lp_select_field:first-child {
          color: #040a2b;
        }
      }
    }
    .lp_input_area {
      .lp_expandable_textarea {
        color: #040a2b;
      }
    }
  }
}

.lp_mobile #lpChat {
  .lp_header {
    .lp_title {
      .lp_top-text {
        font-size: 1.2em;
        font-weight: bold !important;
      }
    }
  }
  .lp_survey_area {
    .lp_pages_area {
      .lp_question_label {
        font-size: 1.2em;
      }
      .lp_select_field,
      input {
        font-size: 1.2em;
      }
    }
  }
  .lp_input_area {
    .lp_expandable_textarea {
      font-size: 1.2em;
    }
  }
  .lp_dialog_container {
    .lp_title {
      font-size: 1.2em;
    }
  }
  .lp_chat_line_wrapper {
    .lp_time {
      font-size: 1em;
    }
    .lp_chat_line {
      .lp_title_text {
        font-size: 1.2em;
      }
    }
  }
}

.product-foundation-vto {
  &__container {
    ul.shade-list-vto {
      li {
        a.swatch {
          word-break: keep-all;
        }
      }
    }
  }
  &__mobile-ctrl {
    .container {
      .product-price {
        .sale-price {
          display: none;
        }
        .taxed-price {
          display: inline;
        }
      }
    }
  }
}

// Header Fixes
.page-header.alt-color-white .page-branding__logo {
  height: 31px;
  margin-left: 0;
  float: right;
  position: relative;
  left: unset;
}
.footer-find-us .menu-item-container {
  flex-direction: column;
}
.shoplist_footer {
  position: relative;
  padding-left: 26px;
  &::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    #{$ldirection}: 0;
    background-color: $color-periwinkle-blue;
    @include svg-icon-inline-mask('location.svg');
  }
}
.footer__sns {
  display: flex;
  flex-direction: column;
  .atcosme {
    margin: 15px auto;
  }
}