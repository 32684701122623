.ui-widget-content {
  height: 12em;
  overflow-y: auto;
}

.clear {
  clear: both;
}

.form_element {
  margin-top: 0.4em;
  margin-bottom: 0.8em;
}

.trust_mark_footer {
  border-top: 1px solid #a4a4ab;
  padding-top: 10px;
  padding-bottom: 10px;
  .trust_mark_footer__link {
    width: 128px;
    height: 145px;
    background-image: url('/media/images/global/lo2-white.png');
    background-repeat: no-repeat;
    text-indent: -9999px;
    display: block;
    margin: auto;
  }
}

.email-signup {
  h3.email_signup__header {
    margin-left: -25px;
    margin-right: -25px;
  }
}

.footer-main-menu {
  .menu-item-container {
    span {
      padding: 10px 0 0 25px;
      display: block;
    }
  }
}

.spp-product__price-size-select {
  text-transform: none;
  letter-spacing: 0.05em;
}

.email_popover {
  .email_popover__social_icons {
    display: none;
  }
}

.contact-form {
  .contact-form__section--question-types {
    .contact-form__sub-question-types {
      display: block;
    }
  }
  h3 span.hdr-right {
    font-size: 15px;
    display: block;
  }
}

.device-mobile {
  #colorbox {
    &.welcome15-overlay {
      #cboxClose,
      #cboxClose:hover {
        font: 0/0 serif;
        text-shadow: none;
        color: transparent;
        background-size: auto auto;
        background-position: 0 -973px;
        width: 28px;
        height: 28px;
        font-size: 12px;
        font-family: 'AkzidenzGrotesk W1G';
        line-height: 21px;
        letter-spacing: 0.05em;
        position: absolute;
        z-index: 100;
        border: none;
        -webkit-appearance: none;
        top: 30px;
        right: 12px;
        background-image: none;
        cursor: pointer;
        width: auto;
        height: auto;
        color: inherit;
        font-size: 12px;
        font-weight: bold;
        line-height: normal;
        text-transform: uppercase;
        text-decoration: underline;
        text-indent: 0;
        bottom: auto;
        padding: 0;
      }
    }
  }
}

.spp-product__additional-section-button {
  width: auto;
}
/* new changes from JP team */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
For SP, iPhone 6+

--- 01 Footer Stikey
--- 02 SPP
--- 03 Font Style
--- 04 Footer Section
--- 05 Other
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* ======================================================
 --- 01 Footer Stikey (for iPhone6)
====================================================== */
@media only screen and (max-width: 716px) {
  .page-sticky-footer__inner .field-mobile-menu .level-1,
  .page-sticky-footer__inner .field-mobile-menu a {
    display: block;
    line-height: 35px;
    padding: 0 12px;
  }
  .page-footer {
    .page-sticky-footer {
      &__right {
        .field-mobile-menu {
          .menu {
            > li {
              margin-top: 1px;
              padding: 0;
            }
          }
        }
      }
    }
  }
}

/* ======================================================
 --- 02 SPP
====================================================== */
.BVCustomSortWrapper {
  padding-top: 25px;
}

.BVRRReviewDisplayStyle3Summary .BVRRContextDataContainer {
  margin-top: 0;
}

.BVRRDisplayContentFooter {
  padding-top: 0;
  padding-bottom: 75px;
}

.spp-product__personal {
  padding-top: 0;
}

.spp-product__personal a {
  display: block;
  line-height: 3em;
  padding: 0 2em;
}

.spp-product__additional-section
  .spp-howtouse
  .spp-howtouse__section1
  .spp-howtouse__header.headline--secondary
  span,
.spp-product__reviews-header--mobile,
.spp-product__details-header {
  font-size: 22px;
}

.spp-product__additional-section
  .spp-howtouse
  .spp-howtouse__section1
  .spp-howtouse__header.headline--secondary {
  line-height: 1em;
}

.brand-renutriv
  .spp-product__additional-section
  .spp-howtouse
  .spp-howtouse__section1
  .spp-howtouse__header.headline--secondary
  span {
  color: #fff;
}

/* ======================================================
 --- 03 Font Style
====================================================== */
/* margin 余白 */
.mb__25px,
.sidebar-page__content-title {
  margin-bottom: 25px;
}

.mt__25px,
.sidebar-page__content-title {
  margin-top: 25px;
}

.node-elc-mpp h1.header__headline.headline--large span {
  font-style: normal;
  text-transform: inherit;
}
/* MPP */
.BVRRRatingNumberPrefix {
  font-size: 34px;
}

.mpp__header,
.BVRRTitle,
/* 店舗検索 */
.section-store-locator.device-mobile .store-locator .store-locator__title,
/* サンプル選択 */
.samples-page {
  .samples-panel__title {
    font-size: 25px;
  }
  .sample-products li {
    .product-details {
      width: 75%;
      float: left;
    }
    label {
      text-align: left;
      padding-top: 2px;
    }
    .form_element {
      clear: both;
    }
  }
}

.brand-renutriv .product-brief__button-quickshop,
.brand-renutriv .product-brief__button-quickshop--close,
.spp-product__details h5,
.product-brief__sub-line,
.spp-product__sub-line {
  font-size: 12px;
}

.spp-product__details h5,
.product-brief__sub-line,
.spp-product__sub-line {
  line-height: 1.45;
}

.product-brief__price,
/* 注文一覧 */
.sku-brief-editorial a .text-product-listing-names-main:nth-child(3) {
  font-size: 14px;
}
/* Quick Shop */
.quickshop-inline__wishlist,
.quickshop-inline__details {
  margin-top: 0;
  line-height: 3;
}
/* 一般 */
p {
  font-size: 13.5px;
}
/* Gnav */
h4,
.promo-unit__headline {
  font-size: 20px;
}

.node-9740 {
  margin-bottom: 1.2em;
  border-bottom: 1px solid rgba(158, 175, 255, 0.5);
}
/* 決済フロー */

.checkout-page-title,
/* 会員情報 */
.account-page .headline--page,
.sidebar-page__content-title {
  font-size: 25px;
}
/* 和文製品名 */

.cart-item__desc-info .name:nth-of-type(2) a {
  font-size: 12px;
}

.remove_link,
.cart-item__desc-info .name:nth-of-type(2) a,
.text-product-listing-names-link {
  display: block;
}

/* ======================================================
 --- 04 Footer Section
====================================================== */

/* メールマガジン登録 用 スタイル設定 */
.email-signup__section input.form-text {
  display: block;
  width: 100%;
  margin: 0 0 5px;
}

.page-footer .email_signup__header {
  color: #fff;
  border-bottom: 0;
  font-size: 15px;
  height: inherit;
}

.page-footer .email_signup__header {
  margin-bottom: 5px;
  padding: 0;
  line-height: inherit;
  font-weight: 800;
}

.email-signup__message {
  display: inline;
}

.footer-forms-location-sms-email-signup-elc-nodeblock .email-signup .form-submit {
  margin-top: 5px;
}
/* 電話番号の部分 */
.footer__tel {
  padding: 16.5px 0 16.5px 25px;
  font-size: 15px;
  display: block;
  line-height: inherit;
}

.footer-main-menu .menu a,
.account-mobile-landing-page .account-landing-menu .account-landing-menu__link,
.text-product-listing-names-main {
  font-size: 15px;
}

.footer__tel {
  border-bottom: 1px solid #1e2341;
}

.page-footer input.form-submit {
  width: 100%;
  display: block;
  margin: 0;
  float: inherit;
}

.footer-find-us {
  margin-left: 0;
  max-width: 100%;
}

.footer-legal a:link,
.footer-legal a:visited {
  color: #9eafff;
}
/* フッター > トラストマーク部 */
.trust_mark_footer {
  margin: 0 !important;
}

.trustmark_p {
  margin: 0;
  padding: 1em 0 1em 0;
  text-align: center;
}

.trustmark_img {
  width: 100%;
  height: auto;
  max-width: 61px;
  padding: 42px 0;
}
/* フッター最下部 */
.node-9240 .footer-forms-location-sms-email-signup-elc-nodeblock .footer-find-us {
  width: 100%;
}

.node-9240 .footer-legal {
  min-height: 88px;
}

.node-9240 .footer-legal a {
  line-height: 44px;
  display: block;
}

.footer-legal .menu li,
.footer-legal .menu li:nth-child(3n + 3) {
  padding-right: 2px;
}
/* --------------------------- iPhone 6+ ヨコ向け専用 --------------------------- */
@media screen and (min-width: 641px) and (max-width: 736px) {
  .node-9216 {
    margin-top: 1em;
  }
  .footer-forms-location-sms-email-signup-elc-nodeblock .footer-find-us {
    width: 100%;
  }
  .node-9472 .content .menu li {
    border-bottom: 1px solid #040a2b;
  }
  .node-9472 .content .menu li.last {
    border-bottom: none;
  }
  .node-9240 .footer-legal a {
    padding: 0 0.5em;
  }
}
/* --------------------------- iPhone 6+ ヨコ以上 --------------------------- */
@media screen and (min-width: 641px) {
  .footer-main-menu .menu a {
    display: block;
    margin: 0;
    padding-left: 25px;
    height: 45px;
    border-bottom: 1px solid #1e2341;
    line-height: 45px;
  }
  .footer-forms-location-sms-email-signup-elc-nodeblock .footer-signup-email {
    padding: 0;
    width: 100%;
  }
  .footer-forms-location-sms-email-signup-elc-nodeblock .footer-signup-email .form-text,
  .footer-forms-location-sms-email-signup-elc-nodeblock .email-signup__section {
    width: 100%;
  }
}
/* --------------------------- iPhone 6+ タテまで --------------------------- */
@media screen and (max-width: 640px) {
  .footer-forms-location-sms-email-signup-elc-nodeblock .email-signup {
    padding-bottom: 0;
  }
}

/* ======================================================
 --- 05 Other
====================================================== */
.li.leaf.expanded.menu-item-container {
  border: none;
}

.email-signup h3.email_signup__header {
  margin: 0 0 5px 0;
}

@media screen and (min-width: 641px) {
  .cart-item__price {
    width: inherit;
    float: inherit;
  }
}

@media screen and (max-width: 678px) {
  .checkout__sidebar .links_list > li .overlay-link {
    display: block;
    padding: 1em 0;
    text-align: center;
  }
}

@media screen and (min-width: 320px) and (max-width: 640px) {
  .mobile-breadcrumb {
    display: none;
  }
  .ee-landing__tile .ee-landing__text h4 {
    font-size: 21px;
    line-height: 1.4;
    margin-bottom: 10px;
  }
  .ee-landing__tile .ee-landing__text .ee-subhead {
    font-size: 13px;
  }
  .ee-hero {
    max-width: 100%;
    margin-left: 0;
    text-align: center;
  }
  .ee-hero img {
    width: 100%;
  }
  .ee-hero__caption {
    width: 96%;
    font-size: 12px;
    margin: 5px auto 0;
    padding-left: 10px;
  }
  h1.header-meta__headline {
    font-size: 55px;
  }
  .header-meta .header-meta__headline {
    margin-bottom: 0.3em;
  }
  span.edith1sub {
    font-size: 14px;
    margin: 16px 0 0;
  }
  .header-meta__subhead {
    width: 94%;
    margin: 0 auto;
    font-size: 18px;
  }
  .formatter-estee-edit__content .basic-textarea-v1 {
    width: 90%;
    margin: 0 auto;
  }
  body blockquote {
    width: 90%;
    font-size: 22px;
    text-align: left;
    line-height: 1.41;
    margin: 26px auto !important;
  }
  .ee-article-image {
    max-width: 100%;
    margin: 0 auto 8px;
    text-align: center;
  }
  .ee-article-image img {
    width: 94%;
  }
  .mb-slideshow__slide-content--portrait .mb-slideshow__slide__copy,
  .mb-slideshow__slide-content--landscape .mb-slideshow__slide__copy {
    width: 90%;
    margin: 0 auto;
  }
  .mb-slideshow__slide__headline {
    font-size: 21px;
    text-align: center;
  }
  .mb-slideshow__slide__caption p {
    font-size: 13px;
    line-height: 1.56;
  }
  .ee-mb-tile__text {
    width: 90%;
    margin: 0 auto;
  }
  .ee-disco-more .ee-disco-more__headline-main {
    margin-bottom: 5px;
  }
  .ee-disco-more .ee-disco-more__headline {
    font-size: 20px;
  }
  .ee-disco-more__image {
    margin: 0 auto 10px;
  }
  #colorbox.colorbox__quickshop
    .quickshop__tabs-control
    .quickshop__tab-control.quickshop__tab-control--2:first-child {
    width: 100%;
    letter-spacing: -0.005em;
    font-size: 30px;
  }
  @media screen and (max-width: 716px) {
    .ee-landing__text a {
      display: block;
      font-size: 14px;
      line-height: 3em;
    }
    .ee-landing__tile .ee-landing__text .ee-subhead {
      margin-bottom: 0;
    }
    .estée-edit-landing-page blockquote:lang(ja):before,
    .estée-edit-landing-page .pull-quote:lang(ja):before,
    .estée-edit-landing-page blockquote:lang(ja):after,
    .estée-edit-landing-page .pull-quote:lang(ja):after {
      font-size: 13.5px;
    }
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .ee-tout__content .header__headline {
      font-size: 80px;
    }
    .ee-landing__tile .ee-landing__text .ee-subhead {
      width: 95%;
      margin: 0 auto 15px;
    }
  }
  @media only screen and (device-width: 768px) {
    .ee-tout__content {
      top: 25%;
    }
  }
}

.page-branding__logo,
.is-alternate .page-branding__logo,
.brand-aerin .is-alternate .page-branding__logo,
.brand-renutriv .is-alternate .page-branding__logo,
.is-sticky .page-branding__logo {
  background-repeat: no-repeat;
}

.device-mobile .spp-product__additional-section .spp-product__additional-section-button {
  width: 100% !important;
}

.tabbed-products-block {
  .grid--mpp__carousel {
    .grid--mpp__items {
      .grid--mpp__item {
        &:not(.slick-active) {
          .product-brief__description {
            visibility: inherit;
          }
        }
      }
    }
  }
}
