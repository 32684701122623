.product-foundation-vto {
  &__mobile-ctrl {
    .container {
      .product-sub-heading,
      .product-name {
        font-size: 12px;
      }
    }
  }
}
#colorbox {
  &.vto-ios-browser-error {
    background-color: $color-white;
  }
}

.vto-active {
  .elc-vto-foundation {
    .elc-vto-custom-shade {
      height: 65px;
    }
    .elc-vto-perfect-shade-label {
      white-space: nowrap;
    }
    .elc-vto-shade-info-left-container,
    .elc-vto-shade-info-right-container {
      width: 50%;
    }
    .elc-add-to-bag-button {
      float: $rdirection;
      padding: 15px;
      width: auto;
    }
    .elc-vto-custom-shade-picker-item-name {
      width: 32px;
    }
  }
}
