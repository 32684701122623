$dd_device-ipad: '(min-width:760px) and (max-width:1024px)';

@media #{$large-up} {
  .device-pc.section-book-appointment {
    .page-header {
      min-height: 82px;
    }
  }
}

#appointment-book-container {
  margin-top: 30px;
  .book-now-bar {
    max-width: 400px;
    .minutes {
      padding: 10px 0 10px;
    }
  }
  .box-price-time-container {
    margin-top: 5px;
    clear: left;
  }
  .box-price-time {
    position: relative;
    float: left;
    width: 65px;
    height: 30px;
    margin-right: 20px;
    margin-left: -15px;
    p {
      text-align: center;
      margin-top: 10px;
      color: $color-black;
      display: block;
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &.services {
    .appt-book-page-header-content {
      &__title {
        line-height: 0.9em;
        margin-top: 0.5em;
        margin-bottom: 0.3em;
      }
    }
  }
  .appt-book-content-header {
    .section-header__inner {
      text-transform: uppercase;
    }
  }
  .location-select-section-content__title {
    @media #{$large-up} {
      font-size: 120px;
      line-height: 110px;
    }
    @media #{$large-down} {
      font-size: 70px;
      line-height: 1em;
    }
  }
  .service-select {
    @media #{$medium-up} {
      .only-time {
        margin-left: -8px;
      }
      .service-select {
        .service {
          .selection-bar {
            padding-top: 5px;
          }
          .service-details {
            h3.service-title {
              margin-bottom: 8px;
              margin-top: 0;
              line-height: 1em;
            }
          }
        }
      }
      .services-skincare {
        max-width: 984px;
        margin: auto;
        .service {
          padding: 0 10px;
          .service-image {
            display: block;
          }
          .service-details {
            left: 0;
            margin-left: 3%;
            .service-title {
              margin-bottom: 0px;
              margin-top: 0px;
            }
          }
        }
      }
    }
    .service {
      @media #{$dd_device-ipad} {
        min-height: 262px;
      }
      .service-details {
        @media #{$large-down} {
          top: 0;
        }
        h3.service-title {
          @media #{$large-down} {
            font-size: 25px !important;
            line-height: 26px !important;
            font-weight: 600;
            margin-top: 1px;
          }
        }
      }
      img[src=''].service-image {
        @media #{$small-only} {
          width: 0;
          display: none;
          & + .service-details {
            width: 92%;
            margin: 10% 4.5% 0;
            .lesson_description {
              br {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  &.counters {
    .appt-book-page-header {
      @media #{$large-down} {
        vertical-align: top;
      }
      @media #{$small-only} {
        margin-top: 16.5%;
      }
    }
  }
  .appointments-container {
    .book-appt-container {
      label {
        .error {
          a {
            color: $color-red;
          }
        }
      }
    }
    @media #{$medium-up} {
      .confirm-container {
        max-width: 650px;
        .start-over {
          margin: 18px 10px 0 10px;
        }
      }
      .sign-in-container {
        .sign-in-form-container {
          .login__password {
            width: 48%;
          }
        }
      }
    }
    .step3 {
      .book-appt-container {
        .appt-book-first-name,
        .appt-book-last-name,
        .appt-book-email,
        .appt-book-mobile,
        .login__password {
          color: $color-navy;
        }
        .appointment_privacy_legal_text {
          span {
            float: left;
            padding-top: 20px;
            font-size: 12px;
            a {
              text-decoration: underline;
            }
          }
        }
        .info-gdpr-for-left,
        .info-gdpr-right-icon {
          display: none;
        }
        .appt-book-last-name {
          float: left;
        }
        .appt-book-first-name {
          float: right;
        }
      }
      .mobile-phone-fieldset {
        @media #{$small-up} {
          float: right;
        }
        .appt-book-mobile-prefix {
          width: 80px;
          float: left;
          border-right: 0;
        }
        .appt-book-mobile {
          width: calc(100% - 80px);
        }
      }
    }
  }
  .confirmation {
    .confirmation-container {
      .artist {
        display: none;
      }
    }
  }
  .my-appointments {
    @media #{$medium-up} {
      max-width: 700px;
    }
    .artist {
      display: none;
    }
    .appointments-buttons {
      .appt-book-link {
        white-space: nowrap;
      }
    }
  }
}
@media #{$large-only} {
  .appt-book-overlay {
    &.appt-book-datepicker-overlay {
      .overlay-content-container {
        padding: 50px 40px;
        width: 810px;
        margin-left: -405px;
        th,
        td .pika-button {
          font-size: 14px !important;
        }
      }
    }
  }
}
@media #{$small-only} {
  #appointment-book-sections {
    .appt-book-location-selector {
      margin-top: 10%;
    }
    .book-appt-container {
      input {
        font-size: 9px;
        &::placeholder {
          font-size: 9px;
        }
      }
    }
    .basic-responsive {
      .location-select-section-content {
        &__title {
          font-size: 40px;
        }
        &__subhead {
          min-height: 415px;
        }
        &__desc {
          width: 100%;
          margin-top: 5%;
          text-align: center;
        }
        &__msg {
          width: 100%;
          margin-left: 0%;
        }
      }
    }
  }
}
