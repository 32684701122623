.colorbox__wishlist-confirm #cboxContent {
  height: auto !important;
}

.colorbox__wishlist-confirm #cboxContent #cboxLoadedContent {
  height: auto !important;
}

body.page-product .shade-select.selectBox-dropdown .selectBox-label {
  font-size: 7px !important;
  padding-right: 0px;
}

body.page-product .selectBox-options.selectBox-dropdown-menu li a {
  font-size: 9px !important;
}

body.page-product .shade-select.selectBox-dropdown .swatch__container,
body.page-product .shade-select-selectBox-dropdown-menu .swatch__container {
  margin: 7px 3px 0 3px;
}

.colorbox__wishlist-confirm #cboxContent {
  height: auto !important;
}

.colorbox__wishlist-confirm #cboxContent #cboxLoadedContent {
  height: auto !important;
}

.add_cart_response #cboxContent #cboxLoadedContent {
  margin-top: 50px;
}

.cart-confirm.one_time_hide {
  display: none !important;
}

.olapic-carousel-list-container .olapic-carousel-partial-container {
  background-image: url('/media/export/cms/olapic/OLAPIC_TOUT_FINAL_MOBILE_japanese.jpg');
}

// 2018 Creative refresh
.utility-nav__item .utility-nav__signin-text p {
  margin: 0;
  text-align: center;
  letter-spacing: 0;
  font-size: 11px;
  padding-top: 10px;
  line-height: 14px;
  @media #{$device-ipad_only} {
    padding-top: 0;
    display: inline-table;
  }
  @media #{$iphone5-portrait} {
    font-size: 8px;
  }
}

.page-navigation__menu {
  .menu-ref {
    .menu__item--link,
    &__title {
      @media #{$device-iphone-landscape-up} {
        font-size: 2vw;
        line-height: 2.625vw;
      }
      @media #{$medium-up} {
        font-size: 3.75vw;
      }
      @media #{$device-ipad_mini} {
        font-size: 18px;
        line-height: 1.3;
      }
      @media #{$xlarge-up} {
        font-size: 12px;
        line-height: normal;
      }
    }
    .menu__item--link {
      @media #{$xlarge-up} {
        font-size: 15px;
        line-height: 17px;
      }
    }
  }
}

.device-mobile {
  .page-footer {
    z-index: 2;
  }
  .zdc {
    &_popup {
      &#colorbox {
        height: 380px !important;
        margin-top: 13em;
      }
      #cboxClose {
        background: $sprites-sprites no-repeat;
      }
    }
  }
}

.field-mobile-template {
  .spp {
    .spp-product {
      &__details-ingredients-wrapper {
        font-size: 14px;
      }
    }
  }
}

.section-re-nutriv,
.brand-renutriv {
  .page-wrapper {
    .page-navigation__menu {
      .menu__item--link a {
        color: $color-navy;
      }
    }
  }
}

.vto-skin-care-formatter {
  .vto-skin-care-screens {
    &__tips-container {
      li {
        font-size: 15px;
        text-align: $ldirection;
      }
    }
    &__tips-title {
      font-size: 26px;
      line-height: 1.07;
    }
  }
  .elc-vto-skin-care {
    .sd-product-grid {
      .elc-grid-item-product {
        .elc-product-prices-wrapper,
        .elc-product-prices-clickable-wrapper {
          display: flex;
        }
        .elc-product-brief-detail-wrapper {
          .elc-product-item-wrapper {
            display: flex;
          }
        }
        .elc-product-tax-included-label-wrapper {
          display: block;
        }
      }
    }
  }
}
.zero-dollar-overlay {
  text-align: center;
  .zero-dollar-buttons {
    a {
      display: inline-block;
      width: 170px;
      background-color: $color-black;
      color: $color-white;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
.brand-style-renutriv {
  .sd-product-grid {
    .elc-grid-container {
      .elc-product-brief-view {
        .elc-grid-item-product {
          .elc-product-brief-detail-wrapper,
          .elc-product-brief {
            .elc-product-product-name-wrapper,
            .elc-product-display-name-wrapper {
              .elc-product-name-wrapper {
                .elc-product-display-name,
                .elc-product-display-name-link {
                  font-size: 21px !important;
                }
              }
              .elc-product-subheader {
                font-size: 21px !important;
              }
            }
            .elc-product-sub-display-name {
              font-size: 16px;
              margin: 10px;
            }
            .elc-product-description-wrapper,
            .elc-short-description-container {
              .elc-product-description-wrapper,
              .elc-product-short-description-wrapper {
                font-size: 14px !important;
              }
            }
            .elc-product-price-wrapper,
            .elc-product-prices-wrapper {
              .elc-product-price-row-wrapper {
                .elc-product-original-price {
                  font-size: 14px;
                }
              }
            }
            .elc-product-shades-number-wrapper,
            .elc-product-shades-count-wrapper {
              font-size: 14px;
              .elc-product-shades-number {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

.faq-formatter__subheader {
  .faq-formatter__toggle {
    @include swap_direction(padding, 6px 25px 6px 0);
    &-icon {
      #{$rdirection}: 0;
    }
  }
}

.promo-panel {
  display: block;
}
