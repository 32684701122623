$disabled-btn-bg: #e0e0e0;
$disabled-btn-text-color: #757575;

.elc-product-full,
.elc-sticky-container .elc-sticky-menu-product-cta,
.elc-product-brief {
  .elc-product-cta-wrapper .elc-product-notify-me-button {
    background: $disabled-btn-bg;
    color: $disabled-btn-text-color;
    pointer-events: none;
    &:hover {
      background: $disabled-btn-bg;
      color: $disabled-btn-text-color;
      pointer-events: none;
    }
  }
}

.elc-product-full {
  .elc-product-promo-message {
    @include swap_direction(margin, 0 30px 15px);
    width: auto;
    @media #{$device-iphone-landscape-up} {
      margin-top: 15px;
    }
    @media #{$medium-up} {
      @include swap_direction(margin, 20px 0 5px);
      width: 100%;
    }
  }
}
