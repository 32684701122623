@if $password_strengthen {
  #signin {
    .sign-in-component {
      &__password-wrapper {
        &.password-field {
          overflow: visible;
        }
      }
    }
  }
  .registration-page {
    &__content {
      .profile-info {
        &__change_pwd_link {
          margin-top: 8px;
          text-decoration: underline;
        }
      }
    }
  }
  .password-update-overlay {
    .profile-password-update {
      &__rules {
        li {
          display: flex;
        }
      }
    }
  }
  .password-field {
    &__info {
      #signin & {
        @media #{$medium-up} {
          -webkit-transform: translateY(-27%);
          -moz-transform: translateY(-27%);
          -o-transform: translateY(-27%);
          transform: translateY(-27%);
          top: 0;
        }
      }
      #password_reset & {
        @media #{$medium-up} {
          top: 0;
          -webkit-transform: translateY(3%);
          -moz-transform: translateY(3%);
          -o-transform: translateY(3%);
          transform: translateY(3%);
        }
      }
      &-checkout {
        @media #{$medium-up} {
          margin-top: 5px;
          #{$ldirection}: 42%;
          position: absolute;
          border: 1px solid $color-black;
          width: 200px;
          -webkit-transform: translateY(-74%);
          -moz-transform: translateY(-74%);
          -o-transform: translateY(-74%);
          transform: translateY(-74%);
        }
        &::before {
          @media #{$medium-up} {
            content: '';
            position: absolute;
            top: 43%;
            #{$rdirection}: 100%;
            border: 5px solid;
            border-color: transparent $color-dark-gray transparent transparent;
          }
        }
      }
    }
    &__rules {
      font-size: 9px;
      @media #{$medium-up} {
        font-size: 12px;
      }
      .checkout & {
        @media #{$medium-up} {
          column-count: 1;
        }
      }
    }
  }
}
