// Fonts
$font_1: 'OptimaDisplayLight', '游明朝', 'YuMincho', 'ヒラギノ明朝 ProN W3', 'HiraMinProN-W3', 'ＭＳ Ｐ明朝',
  'MS PMincho', 'serif', 'sans-serif', 'Tahoma', 'arial';
$font_2: 'ヒラギノ角ゴ Pro W3', 'arial important', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'sans-serif',
  'Tahoma';
$font_3: 'Optima Display Roman', '游明朝', 'arial important', 'YuMincho', 'ヒラギノ明朝 ProN W3', 'HiraMinProN-W3',
  'ＭＳ Ｐ明朝', 'MS PMincho', 'serif', 'sans-serif', 'Tahoma';
$font_4: 'FuturaStd-Heavy', '游明朝', 'YuMincho', 'ヒラギノ明朝 ProN W3', 'HiraMinProN-W3', 'ＭＳ Ｐ明朝', 'MS PMincho',
  'serif', 'sans-serif', 'Tahoma', 'arial';
$font_5: 'Futura Now Regular', '游明朝', 'YuMincho', 'ヒラギノ明朝 ProN W3', 'HiraMinProN-W3', 'ＭＳ Ｐ明朝', 'MS PMincho',
  'serif', 'sans-serif', 'Tahoma', 'arial';
$font_6: 'FuturaStd-Medium', '游明朝', 'YuMincho', 'ヒラギノ明朝 ProN W3', 'HiraMinProN-W3', 'ＭＳ Ｐ明朝', 'MS PMincho',
  'serif', 'sans-serif', 'Tahoma', 'arial';
$font_7: 'NarzissRegular', '游明朝', 'YuMincho', 'ヒラギノ明朝 ProN W3', 'HiraMinProN-W3', 'ＭＳ Ｐ明朝', 'MS PMincho',
  'serif', 'sans-serif', 'Tahoma', 'arial';
$font_8: 'Bauer Bodoni W01 Roman', '游明朝', 'YuMincho', 'ヒラギノ明朝 ProN W3', 'HiraMinProN-W3', 'ＭＳ Ｐ明朝',
  'MS PMincho', 'serif', 'sans-serif', 'Tahoma', 'arial';
$font_9: 'Bauer Bodoni W01 Italic', '游明朝', 'YuMincho', 'ヒラギノ明朝 ProN W3', 'HiraMinProN-W3', 'ＭＳ Ｐ明朝',
  'MS PMincho', 'serif', 'sans-serif', 'Tahoma', 'arial';
$font_10: 'Didot W01 Roman', '游明朝', 'YuMincho', 'ヒラギノ明朝 ProN W3', 'HiraMinProN-W3', 'ＭＳ Ｐ明朝', 'MS PMincho',
  'serif', 'sans-serif', 'Tahoma', 'arial';
$font_11: 'helvetica-neue', '游明朝', 'YuMincho', 'ヒラギノ明朝 ProN W3', 'HiraMinProN-W3', 'ＭＳ Ｐ明朝', 'MS PMincho',
  'serif', 'sans-serif', 'Tahoma', 'arial';
$font_12: '游明朝', 'arial important', 'YuMincho', 'ヒラギノ明朝 ProN W3', 'HiraMinProN-W3', 'ＭＳ Ｐ明朝', 'MS PMincho',
  'serif', 'sans-serif', 'Tahoma';
